import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import dayjs from 'dayjs';



Vue.config.productionTip = false

axios.defaults.baseURL = "https://api.kubz-game.com/api/";

Vue.prototype.$http = axios;

Vue.prototype.$daysJs = dayjs;

require('dayjs/locale/fr');
dayjs.locale('fr') ;



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
