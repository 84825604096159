<template>
  <div class="home">
    <h1 class="title">
      Le jeu qui te mets la tête au carré !
    </h1>
    <div class="video">
      <img src="../../public/images/Equipe.gif" alt="">
    </div>
    <div class="button">
      <g-button></g-button>
    </div>
  </div>
</template>

<script>

import GButton from '@/components/gButton.vue';

export default {
  name: 'Home',
  components: { GButton },
};
</script>
<style>
.home{
  display: flex;
  padding: 50px;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.title{
  margin-bottom: 45px;
}
.button{
  margin-top: 25px;
}
</style>
