<template>
  <section>

    <div class="wrapper">
      <table>
        <thead>
        <tr>
          <th>Rang</th>
          <th>Pseudo</th>
          <th>Points</th>
          <th>Temps de jeu</th>
          <th>Date</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(rank,index) in rankings">
          <td class="rank">{{index +1}}</td>
          <td class="team">{{rank.username}}</td>
          <td class="points">{{rank.score}}</td>
          <td class="points">{{rank.timePlayed}} s</td>
          <td class="up-down">{{ getDate(rank.date)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>

import dayjs from 'dayjs';

export default {
  name: 'Ranking',
  data() {
    return {
      rankings: [],
      getDate: function(date) {

        require('dayjs/locale/fr');
        dayjs.locale('fr');
        return this.$daysJs(date).format("DD/MM/YY");
      }
    };
  },
  mounted() {
    this.$http
        .get("/classement/10/", {})
        .then(response => {
          this.rankings = response.data;
        })
  },
};
</script>

<style scoped>

.container > header {
  margin: 0  auto;
  padding: 1em;
  text-align: center;
}

.container > header h1 {
  font-weight: 600;
  font-size: 3em;
  margin: 0;
}

.wrapper {
  line-height: 1.5em;
  margin: 0 auto;
  padding: 2em 0 3em;
  width: 90%;
  max-width: 2000px;
  overflow: hidden;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 25px;
}

th {
  background-color: #1a1a1a;
  font-weight: bold;
  white-space: nowrap;
}

td, th {
  padding: 1em 1.5em;
  text-align: left;
}

tbody tr:nth-child(2n) {
  background-color: var(--background-secondaey);
  transition: all .125s ease-in-out;
}
tbody tr:hover {
  background-color: var(--color-accent)
}

td.rank {
  text-transform: capitalize;
}
</style>
